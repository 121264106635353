import React from "react"
import ImageMeta from "../../components/ImageMeta"
import { Link } from "gatsby"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

// Only is set up for 2 or 5 docs
function MeetOurDoctors(props) {
  const data = props.post.meetOurDoctors
  const docLength = data.images.length
  const esDocClass = props.language === "es" ? "doc-info-es" : ""

  return (
    <section className={`body-section doc-info color-back ${esDocClass}`}>
      <div className="columns">
        <div className="column is-4"></div>
        <div
          className="column has-text-centered-tablet"
          dangerouslySetInnerHTML={createHtml(converter.makeHtml(data.text))}
        ></div>
        <div className="column is-4"></div>
      </div>
      <div
        className={`columns our-docs has-${docLength}-docs`}
        style={{ marginTop: "3.5rem" }}
      >
        <div className="column is-2"></div>
        {docLength === 1 && (
          <>
            <div className="column"></div>
            {data.images.map((image, i) => (
              <>
                <div key={image.doctor.url} className="column">
                  <Link to={image.doctor.url}>
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={image.doctor.imageId}
                      width="auto"
                      responsive
                      className="meet-our-doctor"
                    ></ImageMeta>
                  </Link>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor">
                      {image.doctor.caption}
                    </h5>
                  </Link>
                </div>
              </>
            ))}
            <div className="column"></div>
          </>
        )}
        {/* <div className="column">
          <div className="columns is-multiline even-doctors"> */}
        {props.columns === 2 && (
          <>
            {data.images.map((image, i) => {
              let doctorUrl = image.doctor.url
              if (props.language === "es") {
                doctorUrl = "/" + doctorUrl
              }
              return (
                <>
                  <div key={image.doctor.url} className="column is-12">
                    <Link to={doctorUrl}>
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={image.doctor.imageId}
                        width="auto"
                        responsive
                        className="meet-our-doctor"
                      ></ImageMeta>
                    </Link>
                    <Link to={doctorUrl}>
                      <h5 className="image-caption doctor">
                        {image.doctor.caption}
                      </h5>
                    </Link>
                  </div>
                </>
              )
            })}
          </>
        )}
        {/* </div>
        </div> */}
        {docLength === 3 && (
          <>
            <div className="column is-2"></div>
            {data.images.map((image, i) => (
              <>
                <div key={image.doctor.url} className="column">
                  <Link to={image.doctor.url}>
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={image.doctor.imageId}
                      width="auto"
                      responsive
                      className="meet-our-doctor"
                    ></ImageMeta>
                  </Link>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor">
                      {image.doctor.caption}
                    </h5>
                  </Link>
                </div>
                {i < 2 && <div className="column is-1"></div>}
              </>
            ))}
            <div className="column is-2"></div>
          </>
        )}

        {docLength === 4 && (
          <div className="columns is-multiline is-centered">
            {data.images.map((image, i) => {
              return (
                <>
                  <div
                    key={image.doctor.url}
                    className="column is-10 doc-column"
                  >
                    <Link to={image.doctor.url}>
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={image.doctor.imageId}
                        responsive
                        className="meet-our-doctor"
                      ></ImageMeta>
                    </Link>
                    <Link to={image.doctor.url}>
                      <h5 className="image-caption doctor">
                        {image.doctor.caption}
                      </h5>
                    </Link>
                  </div>
                  {i === 0 && <div className="column is-1"></div>}
                  {i === 2 && docLength && <div className="column is-1"></div>}
                </>
              )
            })}
          </div>
        )}

        {docLength === 5 && (
          <>
            {data.images.map((image, i) => {
              return (
                <>
                  {i % 2 === 0 && <div className="column is-4"></div>}
                  <div
                    key={image.doctor.url}
                    className="column is-7 doc-column"
                  >
                    <Link to={image.doctor.url}>
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={image.doctor.imageId}
                        width="auto"
                        responsive
                        className="meet-our-doctor"
                      ></ImageMeta>
                    </Link>
                    <Link to={image.doctor.url}>
                      <h5 className="image-caption doctor">
                        {image.doctor.caption}
                      </h5>
                    </Link>
                  </div>
                  {i % 2 === 0 && <div className="column is-1"></div>}
                  {i % 2 === 1 && <div className="column is-5"></div>}
                </>
              )
            })}
          </>
        )}
        <div className="column is-2"></div>
      </div>
    </section>
  )
}

export default MeetOurDoctors
